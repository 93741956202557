<template>
  <div>
    <el-dialog
      :visible.sync="smsVerifyDialog"
      width="350px"
      :append-to-body="true"
    >
      <slide-verify
        ref="slideblock"
        @again="onAgain"
        @fulfilled="onFulfilled"
        @success="onSuccess"
        @fail="onFail"
        @refresh="onRefresh"
        :accuracy="accuracy"
        :slider-text="text"
        :imgs="slideImages"
      ></slide-verify>

      <div>{{ msg }}</div>
    </el-dialog>
    <el-form
      :model="regForm"
      :rules="rules"
      ref="regForm"
      label-width="100px"
      class="register-form"
    >
      <el-form-item label="手机号" prop="mobile">
        <el-input
          type="mobile"
          v-model="regForm.mobile"
          autocomplete="off"
        ></el-input>
      </el-form-item>

      <el-form-item label="短信验证码" prop="smscode" class="sms-code ">
        <el-input
          v-model="regForm.smscode"
          placeholder="请输入验证码"
        ></el-input>
        <el-button
          type="button"
          @click="sendCode"
          :disabled="smsDisabled"
          v-if="smsDisabled == false"
        >
          发送验证码
        </el-button>
        <el-button
          type="button"
          @click="sendCode"
          :disabled="smsDisabled"
          v-if="smsDisabled == true"
        >
          {{ btntxt }}
        </el-button>
      </el-form-item>
      <el-form-item label="用户名" prop="username">
        <el-input v-model="regForm.username" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="pass">
        <el-input
          type="password"
          v-model="regForm.pass"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input
          type="password"
          v-model="regForm.checkPass"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input
          type="email"
          v-model="regForm.email"
          autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer-reg">
      <el-button type="primary" @click="register('regForm')">提交</el-button>
      <!--          <el-button @click="resetRegForm('regForm')">重置</el-button>-->
      <div class="reg-footer-text" @click="onLogin">
        已有账号，立刻登录
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Register",

  data() {
    var validateMobile = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
      if (!value) {
        return callback(new Error("电话号码不能为空"));
      }
      setTimeout(() => {
        // Number.isInteger是es6验证数字是否为整数的方法,但是我实际用的时候输入的数字总是识别成字符串
        // 所以我就在前面加了一个+实现隐式转换

        if (!Number.isInteger(+value)) {
          callback(new Error("请输入数字值"));
        } else {
          if (phoneReg.test(value)) {
            callback();
          } else {
            callback(new Error("电话号码格式不正确"));
          }
        }
      }, 100);
    };
    var validateUsername = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入用户名"));
      } else {
        if (this.regForm.username !== "") {
          // this.$refs.form.validateField("username");
        }
        callback();
      }
    };
    var validateSmscode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入短信验证码"));
      } else {
        if (this.regForm.smscode !== "") {
          // this.$refs.form.validateField("smscode");
        }
        callback();
      }
    };
    var validateEmail = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      if (!value) {
        return callback(new Error("邮箱不能为空"));
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback();
        } else {
          callback(new Error("请输入正确的邮箱格式"));
        }
      }, 100);
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.regForm.checkPass !== "") {
          // this.$refs.form.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.regForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    return {
      smsDisabled: false,
      smsVerifyDialog: false,
      time: 0,
      btntxt: "重新发送",
      text: "向右滑",
      msg: "",
      accuracy: 3,
      slideImages: [
        "https://alioss.zsihan.cn/zshpub-verify-img/1.jpg",
        "https://alioss.zsihan.cn/zshpub-verify-img/2.jpg",
        "https://alioss.zsihan.cn/zshpub-verify-img/3.jpg",
        "https://alioss.zsihan.cn/zshpub-verify-img/4.jpg",
        "https://alioss.zsihan.cn/zshpub-verify-img/5.jpg"
      ],
      regForm: {
        mobile: "",
        smscode: "",
        username: "",
        email: "",
        pass: "",
        checkPass: ""
      },
      formVisble: {
        registerFormVisible: false,
        loginFormVisible: false
      },
      rules: {
        mobile: [{ validator: validateMobile, trigger: "blur" }],
        smscode: [{ validator: validateSmscode, trigger: "blur" }],
        username: [{ validator: validateUsername, trigger: "blur" }],
        email: [{ validator: validateEmail, trigger: "blur" }],
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }]
      }
    };
  },
  methods: {
    //60S倒计时
    timer() {
      if (this.time > 0) {
        this.time--;
        this.btntxt = this.time + "s后重新获取";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = "获取验证码";
        this.smsDisabled = false;
      }
    },
    onLogin() {
      this.formVisble.registerFormVisible = false;
      this.formVisble.loginFormVisible = true;
      this.$emit("FormVisible", this.formVisble);
    },
    resetRegForm(regForm) {
      this.$refs[regForm].resetFields();
    },
    register(regForm) {
      var that = this;
      this.$refs[regForm].validate(valid => {
        if (valid) {
          that.regForm.sign = that.$sign.getSign(that.regForm);
          that.$http
            .post("/user/register", that.regForm)
            .then(function(res) {
              var data = JSON.parse(res.data);

              if (data.code == 200) {
                that.$alert(data.msg, "友情提示");
                that.resetRegForm("regForm");
                that.formVisble.registerFormVisible = false;
                that.formVisble.loginFormVisible = false;
                that.$emit("FormVisible", that.formVisble);
              } else {
                that.$alert(data.msg, "友情提示");
              }
            })
            .catch(function(err) {
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    login() {},

    onSuccess() {
      var that = this;
      var sendMobileJson = { mobile: that.regForm.mobile, sign: "" };
      sendMobileJson.sign = this.$sign.getSign(sendMobileJson);
      this.$http
        .post("/user/sendsms", sendMobileJson)
        .then(function(res) {
          var data = JSON.parse(res.data);

          if (data.code == 200) {
            that.time = 60;
            that.smsDisabled = true;
            that.timer();
            that.smsVerifyDialog = false;
          } else {
            that.$alert(data.msg, "友情提示");
          }
        })
        .catch(function(err) {
          console.log(err);
        });
    },
    onFail() {
      // console.log("验证不通过");
      this.msg = "验证失败";
    },
    onRefresh() {
      // console.log("点击了刷新小图标");
      this.msg = "";
    },
    onFulfilled() {
      // console.log("刷新成功啦！");
    },
    onAgain() {
      // console.log("检测到非人为操作的哦！");
      this.msg = "try again";
      // 刷新
      this.$refs.slideblock.reset();
    },
    handleClick() {
      // 父组件直接可以调用刷新方法
      this.$refs.slideblock.reset();
    },
    sendCode() {
      this.$refs.regForm.validateField("mobile", errMsg => {
        if (!errMsg) {
          this.smsVerifyDialog = true;
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style scoped></style>
