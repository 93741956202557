<template>
  <div class="content">
    <h1>{{ msg }}</h1>
    <div class="sos">
      <el-form :inline="true" :model="formInline" class="so-btn-group ">
        <el-form-item>
          <!--                    <el-input v-model="input" placeholder="请输入生成短链接地址" id="so-input" class="url-input"></el-input>-->
          <el-input
            v-model="formInline.text"
            type="textarea"
            placeholder="请输入生成短链接地址,一行一个,未登录状态一次生成一个，登录后可批量生成"
            id="so-input"
            class="url-input"
          ></el-input>
        </el-form-item>

        <el-select
          v-model="formInline.effective"
          placeholder="选择链接有效期"
          class="effective"
        >
          <el-option
            v-for="item in effectiveList"
            :key="item.label"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <!--                <el-form-item label-position="right">-->
        <el-button class="btn" label-position="right" @click="submit"
          >一键生成
        </el-button>
        <!--                </el-form-item>-->
      </el-form>
      <el-divider></el-divider>
      <div class="successText">
        {{ successText }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String
  },
  data() {
    return {
      isLogin: false,
      successText: "",
      effectiveList: [
        { label: "七天", value: 1 },
        { label: "一个月", value: 2 },
        { label: "三个月", value: 3 },
        { label: "一年", value: 4 },
        { label: "永久", value: 5 }
      ],
      formInline: {
        text: "",
        effective: 5,
        sign: ""
      }
    };
  },
  methods: {
    checkDomain(index, enDomain) {
      index++;
      var ii;
      if (enDomain == "" || enDomain == " " || enDomain.length < 4) {
        this.$alert("行：" + index + "-请您输入有效的域名！");
        return false;
      }
      var checkOK =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-0123456789|.!=+%/_: ";
      var allValid = true;
      for (var i = 0; i < enDomain.length; i++) {
        var ch = enDomain.charAt(i);
        for (var j = 0; j < checkOK.length; j++)
          if (ch == checkOK.charAt(j)) break;
        if (j == checkOK.length) {
          allValid = false;
          break;
        }
      }
      if (!allValid) {
        // this.$alert("行：" + index + " -请输入有效的域名或路径！");
        // return (false);
      }
      if (enDomain.length > 0) {
        ii = enDomain.indexOf(".");

        if (ii == -1) {
          this.$alert(
            "行：" + index + " -有效的域名或路径中必须含有“.”和域名后缀！"
          );
          return false;
        }
      }
      return true;
    },
    submit() {
      if (this.formInline.text == "") {
        this.$alert("地址不能为空！", "友情提示");
        return;
      }
      if (this.formInline.effective == "" || this.formInline.effective <= 0) {
        this.$alert("请选择生成时间！", "友情提示");
        return;
      }
      var text = this.formInline.text.split("\n");

      if (this.isLogin) {
        if (text.length > 50) {
          this.$alert("登录用户最多生成50条！", "友情提示");
          return;
        }
      } else {
        if (text.length > 1) {
          this.$alert("未登录用户最多生成1条！", "友情提示");
          return;
        }
      }

      for (let key in text) {
        if (this.checkDomain(key, text[key]) === false) {
          return false;
        }
      }
      var that = this;
      this.formInline.sign = this.$sign.getSign(this.formInline);
      this.$http
        .post("/link", this.formInline)
        .then(function(res) {
          var data = JSON.parse(res.data);
          if (data.code == 200) {
            that.successText =
              "结果：" + window.location.host + "/" + data.data[0].code;
            that.$alert("查看更多短链接请登录到个人中心查看", "友情提示");
          } else {
            that.$alert(data.msg, "友情提示");
          }
        })
        .catch(function(err) {
          console.log(err);
        });
    },
    index() {
      // this.$http.get("/user/43534")
      //   .then(function(res) {
      //     console.log(res);
      //   }).catch(function(err) {
      //   console.log(err);
      // });
      // this.$http.post("/user/43534")
      //   .then(function(res) {
      //     console.log(res);
      //   }).catch(function(err) {
      //   console.log(err);
      // });
      //
      // this.$http.delete("/user/43534", { a: "" })
      //   .then(function(res) {
      //     console.log(res);
      //   }).catch(function(err) {
      //   console.log(err);
      // });
    }
  },
  created() {
    this.isLogin = localStorage.getItem("access_token") ? true : false;
    this.index();
  }
};
</script>
