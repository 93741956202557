<template>
  <div class="content" v-if="isLogin">
    <el-tabs v-model="activeName" @tab-click="handleClick" class="user-tabs">
      <el-tab-pane label="个人信息" name="info"><Info /></el-tab-pane>

      <el-tab-pane label="短链接" name="csha1"><Link /></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Info from "@/components/user/Info.vue";

import Link from "@/components/user/Link.vue";

import "../assets/css/user.less";
export default {
  name: "Encryptions",
  components: {
    Info,
    Link
  },
  data() {
    return {
      activeName: "info",
      isLogin: false
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab);
      console.log(event);
    }
  },
  created() {
    this.isLogin = localStorage.getItem("access_token") ? true : false;
    if (!this.isLogin) {
      this.$router.push({ path: "/" });
    }
  }
};
</script>
