<template>
  <div>
    <el-form
      :model="loginForm"
      :rules="rules"
      ref="loginForm"
      label-width="100px"
      class="register-form"
    >
      <el-form-item label="手机号/邮箱" prop="mobile">
        <el-input
          type="mobile"
          v-model="loginForm.mobile"
          autocomplete="off"
        ></el-input>
      </el-form-item>

      <el-form-item label="密   码" prop="pass">
        <el-input
          type="password"
          v-model="loginForm.pass"
          autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer-reg">
      <el-button type="primary" @click="login('loginForm')">提交</el-button>

      <div class="login-footer-text">
        <span @click="onRegister">免费注册</span> ·<span @click="onRestPwd"
          >忘记密码</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    var validateMobile = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      if (!value) {
        return callback(new Error("账号不能为空"));
      }
      setTimeout(() => {
        // Number.isInteger是es6验证数字是否为整数的方法,但是我实际用的时候输入的数字总是识别成字符串
        // 所以我就在前面加了一个+实现隐式转换

        if (!Number.isInteger(+value)) {
          if (mailReg.test(value)) {
            this.loginForm.login_type = "2";
            callback();
          } else {
            callback(new Error("请输入正确的手机号或邮箱格式"));
          }
        } else {
          if (phoneReg.test(value)) {
            this.loginForm.login_type = "1";
            callback();
          } else {
            if (mailReg.test(value)) {
              this.loginForm.login_type = "2";
              callback();
            } else {
              callback(new Error("请输入正确的手机号或邮箱格式"));
            }

            // callback(new Error("电话号码格式不正确"));
          }
        }
      }, 100);
    };

    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      }
      callback();
    };
    return {
      formVisble: {
        registerFormVisible: false,
        loginFormVisible: false
      },
      loginForm: {
        mobile: "",
        pass: "",
        login_type: 3
      },
      rules: {
        mobile: [{ validator: validateMobile, trigger: "blur" }],
        pass: [{ validator: validatePass, trigger: "blur" }]
      }
    };
  },
  methods: {
    onRegister() {
      this.formVisble.registerFormVisible = true;
      this.formVisble.loginFormVisible = false;
      this.$emit("FormVisible", this.formVisble);
    },
    onRestPwd() {
      this.formVisble.restPwdFormVisible = true;
      this.formVisble.registerFormVisible = false;
      this.formVisble.loginFormVisible = false;
      this.$emit("FormVisible", this.formVisble);
    },
    login(loginForm) {
      var that = this;
      this.$refs[loginForm].validate(valid => {
        if (valid) {
          that.loginForm.sign = that.$sign.getSign(that.loginForm);
          that.$http
            .post("/login", that.loginForm)
            .then(function(res) {
              var data = JSON.parse(res.data);

              if (data.code == 200) {
                localStorage.setItem("access_token", data.data.api_token);
                localStorage.setItem("users", JSON.stringify(data.data));
                that.$alert(data.msg, "友情提示");

                that.$refs["loginForm"].resetFields();
                that.formVisble.restPwdFormVisible = false;
                that.formVisble.registerFormVisible = false;
                that.formVisble.loginFormVisible = false;
                that.$emit("FormVisible", that.formVisble);
                location.reload();
              } else {
                that.$alert(data.msg, "友情提示");
              }
            })
            .catch(function(err) {
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style scoped></style>
