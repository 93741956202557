<template>
  <div class="restPwdContent"></div>
</template>

<script>
export default {
  name: "RestEmail",
  components: {},
  data() {
    return {
      form: {
        email: " ",
        old_email: " ",
        key: " "
      }
    };
  },
  methods: {
    index() {
      let token = this.$route.query.token;
      let email = this.$route.query.email;
      let old_email = this.$route.query.old_email;
      if (email) {
        this.form.email = email;
      }
      if (old_email) {
        this.form.old_email = old_email;
      }
      if (token) {
        this.form.key = token;
      }
      var that = this;
      that.form.sign = that.$sign.getSign(that.form);
      that.$http
        .put("/user/dorestEmail", that.form)
        .then(function(res) {
          var data = JSON.parse(res.data);
          if (data.code == 200) {
            that.$alert(data.msg, "友情提示", {
              confirmButtonText: "邮箱修改成功，确认关闭并跳转首页！",
              center: true,
              callback: action => {
                that.$router.push("/");
                console.log(action);
              }
            });
          } else {
            that.$alert(data.msg, "友情提示");
          }
        })
        .catch(function(err) {
          console.log(err);
        });
    }
  },
  created() {
    this.index();
  }
};
</script>
