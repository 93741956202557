<template>
  <div class="cunicode">
    <div class="block">
      <el-input
        class="ctextarea"
        type="textarea"
        placeholder="请输入内容"
        v-model="text"
        :rows="12"
      >
      </el-input>
      <div class="el-icon-right"></div>
      <el-input
        class="ctextarea"
        type="textarea"
        placeholder="请输入内容"
        v-model="textConvert"
        :rows="12"
      >
      </el-input>
    </div>
    <div class="block btns tr">
      <el-button type="primary" @click="base64Encode">Base64加密</el-button>
      <el-button type="primary" @click="base64Decode">Base64解密</el-button>
    </div>
  </div>
</template>

<script>
let base = require("js-base64").Base64;
export default {
  name: "CUrl",
  data() {
    return {
      textConvert: "",
      text: ""
    };
  },
  methods: {
    base64Encode() {
      this.textConvert = base.encode(this.text);
    },
    base64Decode() {
      this.textConvert = base.decode(this.text);
    }
  }
};
</script>
