<template>
  <div style="">
    <img
      src="@/assets/images/404.png"
      style="top: 25%;left:25%;position: absolute"
    />
  </div>
</template>

<script>
export default {
  name: "NotFound"
};
</script>

<style scoped></style>
