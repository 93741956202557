<template>
  <div id="app" class="block">
    <div id="nav" v-if="status">
      <router-link to="/">短链接生成</router-link>
      |
      <router-link to="/outtime">时间戳转换</router-link>
      |
      <router-link to="/encryptions">普通转码加密</router-link>
      |
      <router-link to="/phps">PHP文件加密</router-link>
      <div class="nav-right-login" v-if="!isLogin">
        <span @click="loginFormVisible = true">登录</span> /
        <span @click="registerFormVisible = true">注册</span>
      </div>
      <div class="nav-right-login" v-if="isLogin">
        <router-link to="/user">
          <span @click="userInfo">{{ users.username }}</span> /
          <span @click="logout">退出</span>
        </router-link>
      </div>

      <el-dialog
        title="用户登录"
        :visible.sync="loginFormVisible"
        class="register-dialog"
        width="500px"
        :modal-append-to-body="false"
      >
        <Login v-on:FormVisible="FormVisible" />
      </el-dialog>
      <el-dialog
        title="用户注册"
        :visible.sync="registerFormVisible"
        class="register-dialog"
        width="500px"
      >
        <Register v-on:FormVisible="FormVisible" />
      </el-dialog>
      <el-dialog
        title="找回密码"
        :visible.sync="restPwdFormVisible"
        class="register-dialog"
        width="500px"
      >
        <RestPwdEmail
          v-on:FormVisible="FormVisible"
          :restPwdButton="restPwdButton"
        />
      </el-dialog>
    </div>

    <router-view />
  </div>
</template>

<script>
import Vue from "vue";
import Login from "@/components/user/Login.vue";
import Register from "@/components/user/Register.vue";
import RestPwdEmail from "@/components/user/RestPwdEmail.vue";
import SlideVerify from "vue-monoplasty-slide-verify";

Vue.use(SlideVerify);

import axios from "./config/http";

Vue.prototype.$http = axios;

export default {
  name: "HelloWorld",
  props: {
    msg: String
  },
  components: {
    Login,
    Register,
    RestPwdEmail
  },
  data() {
    return {
      status: false,
      isLogin: false,
      users: [],
      registerFormVisible: false,
      loginFormVisible: false,
      restPwdFormVisible: false,
      restPwdButton: true
    };
  },
  methods: {
    FormVisible(params) {
      this.registerFormVisible = params["registerFormVisible"];
      this.loginFormVisible = params["loginFormVisible"];
      this.restPwdFormVisible = params["restPwdFormVisible"];
    },
    index() {
      if (localStorage.getItem("access_token")) {
        this.isLogin = true;
        this.users = JSON.parse(localStorage.getItem("users"));
      }
      axios
        .get("/link/t", {
          params: {
            link: window.location.pathname
          }
        })
        .then(res => {
          if (res.data) {
            var data = JSON.parse(res.data);
            if (data.code == 200) {
              window.location = data.data;
            } else {
              var pathname = window.location.pathname;
       var rule=["/NotFound","","/","/user","/restemail","/restpwd","/outtime","/encryptions"]

              if (rule.indexOf(pathname)<0) {
                window.location = "/NotFound";
              } else {
                this.status = true;
              }
            }
          }
        });
    },
    userInfo() {},
    logout() {
      axios
        .get("/user/logout", {
          params: {
            ts: Math.random()
          }
        })
        .then(res => {
          if (res.data) {
            var data = JSON.parse(res.data);
            if (data.code == 200) {
              localStorage.removeItem("access_token");
              location.reload();
            } else {
              this.status = true;
            }
          }
        });
    }
  },

  created() {
    this.index();
  }
};
</script>
<style lang="less">
@import "assets/css/common.less";
</style>
