<template>
  <div class="cunicode">
    <div class="block">
      <el-input
        class="ctextarea"
        type="textarea"
        placeholder="请输入内容"
        v-model="text"
        :rows="12"
      >
      </el-input>
      <div class="el-icon-right"></div>
      <el-input
        class="ctextarea"
        type="textarea"
        placeholder="请输入内容"
        v-model="textConvert"
        :rows="12"
      >
      </el-input>
    </div>
    <div class="block btns tr">
      <el-button type="primary" @click="htmlEnCode">html编码</el-button>
      <el-button type="primary" @click="htmlDeCode">html解码</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CHtml",
  data() {
    return {
      textConvert: "",
      text: "",
      formInline: {
        text: "",
        type: 1,
        sign: ""
      }
    };
  },
  methods: {
    htmlEnCode() {
      var that = this;
      this.formInline.type = 1;
      this.formInline.text = this.text;
      this.formInline.sign = this.$sign.getSign(this.formInline);
      this.$http
        .put("/encryptions/html", this.formInline)
        .then(function(res) {
          var data = JSON.parse(res.data);
          that.textConvert = data.data;
        })
        .catch(function(err) {
          console.log(err);
        });
    },
    htmlDeCode() {
      var that = this;
      this.formInline.type = 2;
      this.formInline.text = this.text;
      this.formInline.sign = this.$sign.getSign(this.formInline);

      this.$http
        .put("/encryptions/html", this.formInline)
        .then(function(res) {
          var data = JSON.parse(res.data);
          that.textConvert = data.data;
        })
        .catch(function(err) {
          console.log(err);
        });
    }
  }
};
</script>
