<template>
  <div class="cunicode">
    <div class="block">
      <el-input
        class="ctextarea"
        type="textarea"
        placeholder="请输入内容"
        v-model="text"
        :rows="12"
      >
      </el-input>
      <div class="el-icon-right"></div>
      <el-input
        class="ctextarea"
        type="textarea"
        placeholder="请输入内容"
        v-model="textConvert"
        :rows="12"
      >
      </el-input>
    </div>
    <div class="block btns tr">
      <el-button type="primary" @click="urlEnCode">UrlEnCode</el-button>
      <el-button type="primary" @click="urlDeCode">UrlDeCode</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CUrl",
  data() {
    return {
      textConvert: "",
      text: ""
    };
  },
  methods: {
    urlEnCode() {
      this.textConvert = encodeURIComponent(this.text);
    },
    urlDeCode() {
      this.textConvert = decodeURIComponent(this.text);
    }
  }
};
</script>
