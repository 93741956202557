<template>
  <div class="cunicode">
    <div class="block">
      <el-input
        class="ctextarea"
        type="textarea"
        placeholder="请输入内容"
        v-model="text"
        :rows="12"
      >
      </el-input>
      <div class="el-icon-right"></div>
      <el-input
        class="ctextarea"
        type="textarea"
        placeholder="请输入内容"
        v-model="textConvert"
        :rows="12"
      >
      </el-input>
    </div>
    <div class="block btns tr">
      <el-button type="primary" @click="asciiUnicode">ASCII转Unicode</el-button>
      <el-button type="primary" @click="unicodeAscii">Unicode转ASCII</el-button>
      <el-button type="primary" @click="unicodeGbk">Unicode转中文</el-button>
      <el-button type="primary" @click="gbkUnicode">中文转Unicode</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Unicode",
  data() {
    return {
      textConvert: "",
      text: ""
    };
  },
  methods: {
    asciiUnicode() {
      var str = this.text;
      var reserved = "";
      for (var i = 0; i < str.length; i++)
        reserved += "&#" + str.charCodeAt(i) + ";";
      this.textConvert = reserved;
    },
    unicodeAscii() {
      var reserved = "";
      var str = this.text;
      console.log(str.match(/&#(\d+);/g));
      var code = str.match(/&#(\d+);/g);
      if (code === null) {
        return str;
      }
      for (var i = 0; i < code.length; i++) {
        reserved += String.fromCharCode(code[i].replace(/[&#;]/g, ""));
      }
      this.textConvert = reserved;
    },
    unicodeGbk() {
      let str = this.text;
      this.textConvert = unescape(str.replace(/\\u/gi, "%u"));
    },
    gbkUnicode() {
      let str = this.text;
      let res = [];
      for (let i = 0; i < str.length; i++) {
        res[i] = ("00" + str.charCodeAt(i).toString(16)).slice(-4);
      }
      this.textConvert = "\\u" + res.join("\\u");
    }
  }
};
</script>
