import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import OutTime from "../views/OutTime";
import Encryptions from "../views/Encryptions";
import RestPwd from "../views/RestPwd";
import RestEmail from "../views/RestEmail";
import User from "../views/User";
import NotFound from "../views/NotFound";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/OutTime",
    name: "OutTime",
    component: OutTime
  },
  {
    path: "/Encryptions",
    name: "Encryptions",
    component: Encryptions
  },
  {
    path: "/RestPwd",
    name: "RestPwd",
    component: RestPwd
  },
  {
    path: "/RestEmail",
    name: "RestEmail",
    component: RestEmail
  },
  {
    path: "/User",
    name: "User",
    component: User
  },
  { path: "/NotFound", component: NotFound }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
