<template>
  <div class="content">
    <h1>时间戳快捷转换与主流语言获取时间戳</h1>
    <div class="times">
      <div class="block nowtimes">
        <span class="demonstration">当前时间：</span
        ><span class="nowtime" @click="nowDate">{{ leftDate }}</span>
        <span class="demonstration">当前时间戳：</span
        ><span class="nowtime" @click="nowTime">{{ leftTime }}</span>
        <span class="demonstration red zclick"
          >注：点击当前时间或时间戳可快速填充！</span
        >
      </div>
      <div class="block">
        <span class="demonstration">时间：</span>
        <el-date-picker
          v-model="dtime"
          type="datetime"
          placeholder="选择日期"
        ></el-date-picker>
        <el-button class="w200 l20" @click="dotime">转换-></el-button>
        <span class="demonstration  l20" value-format="timestamp"
          >时间戳：</span
        >
        <el-input v-model="otime" class="otimes w200 "></el-input>
        <el-select v-model="dotype" placeholder="请选择" class="l20 w200">
          <el-option
            v-for="item in options"
            :key="item.label"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>

      <div class="block">
        <span class="demonstration  " value-format="timestamp">时间戳：</span>
        <el-input v-model="otime2" class="otimes w200 "></el-input>
        <el-select v-model="dotype2" placeholder="请选择" class="l20 w200">
          <el-option
            v-for="item in options"
            :key="item.label"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-button class="w200 l20" @click="dotime2">转换-></el-button>
        <span class="demonstration l20">时间：</span>
        <el-input v-model="dtime2" class="w200"></el-input>
      </div>
    </div>
    <h1>主流语言与数据库获取时间戳</h1>
    <div class="language-times">
      <div class="block">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="name" label="名称" width="200">
          </el-table-column>
          <el-table-column prop="otime" label="获取时间戳"> </el-table-column>

          <el-table-column prop="tdate" label="时间戳转日期"> </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import "../assets/css/outtime.less";

export default {
  name: "OutTime",
  components: {},
  data() {
    return {
      tableData: [
        {
          name: "Java",
          otime: "new Date().getTime()",
          tdate:
            "Date d = new Date();\n" +
            '\t\tSimpleDateFormat sdf = new SimpleDateFormat("yyyy-MM-dd HH:mm:ss");\n' +
            '\t\tString sd = sdf.format(new Date(Long.parseLong( "1606269402"))); '
        },
        {
          name: "PHP",
          otime: "time()",
          tdate: 'date("Y-m-d H:i:s") 或 date("Y-m-d H:i:s "time()")'
        },
        {
          name: "Python",
          otime: "new Date().getTime()",
          tdate:
            'timeArray=time.localtime(1606211099) \n  print(time.strftime("%Y-%m-%d %H:%M:%S", timeArray))'
        },
        {
          name: "C/C++",
          otime:
            '需要引入sys/time.h \n struct timeval tv;\n gettimeofday(&tv, NULL);\n  printf("秒: %ld\\n", tv.tv_sec); // 秒\n   printf("毫秒: %ld\\n", tv.tv_sec * 1000 + tv.tv_usec / 1000); // 毫秒\n' +
            '    printf("微妙: %ld\\n", tv.tv_sec * 1000000 + tv.tv_usec); // 徽秒',
          tdate:
            '需要引入time.h \n time_t t;\nstruct tm *p;  \nt=1384936600;  \np=gmtime(&t);  \n char s[100];  \n strftime(s, sizeof(s), "%Y-%m-%d %H:%M:%S", p);  \n' +
            '    printf("%d: %s\\n", (int)t, s);  '
        },
        {
          name: "C#",
          otime:
            "(DateTime.Now.ToUniversalTime().Ticks - 621355968000000000) / 10000000;",
          tdate:
            "TimeZone.CurrentTimeZone.ToLocalTime( new DateTime( 1970, 1, 1)).AddSeconds(1606271872); "
        },
        {
          name: "Perl",
          otime: "time",
          tdate:
            '$chpasstime = time;\n use POSIX qw(strftime);\n strftime "%Y-%m-%d %H:%M:%S", localtime($chpasstime); '
        },
        { name: "Ruby", otime: "Time.now.to_i;", tdate: "Time.at 1583581066" },
        {
          name: "JavaScript",
          otime: "new Date().getTime()",
          tdate:
            'var date = new Date(1606286299496); \n var Y = date.getFullYear() + "-"; \n var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-"; \n ' +
            'var D =date.getDate() + " "; \n var h = date.getHours() + ":"; \n  var m = date.getMinutes() + ":"; \n  var s = date.getSeconds(); \n dates= Y + M + D + h + m + s;'
        },
        {
          name: "Mysql",
          otime: "SELECT unix_timestamp(now())",
          tdate: "SELECT FROM_UNIXTIME(1606288551)"
        },
        {
          name: "SqlServer",
          otime: "SELECT DATEDIFF(s, '1970-01-01 00:00:00', GETUTCDATE())",
          tdate: "DATEADD(s, Unix timestamp, '1970-01-01 00:00:00')"
        },
        {
          name: "Oracle",
          otime: "select SYSDATE from dual",
          tdate:
            "select cast(to_timestamp('2011-09-14 12:52:42.123456789', 'syyyy-mm-dd hh24:mi:ss.ff') as date) timestamp_to_date from dual"
        }
      ],
      options: [
        { value: 1, label: "秒(s)" },
        { value: 2, label: "毫秒(ms)" }
      ],
      dtime: "",
      otime: "",
      dotype: 1,
      dtime2: "",
      otime2: "",
      dotype2: 1,

      leftTime: 0,
      leftDate: ""
    };
  },
  methods: {
    add0(m) {
      return m < 10 ? "0" + m : m;
    },
    timestampToTime(cjsj) {
      var date = new Date(cjsj); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = this.add0(date.getDate()) + " ";
      var h = this.add0(date.getHours()) + ":";
      var m = this.add0(date.getMinutes()) + ":";
      var s = this.add0(date.getSeconds());
      return Y + M + D + h + m + s;
    },
    nowDate() {
      this.dtime = this.leftDate;
    },
    nowTime() {
      if (this.dotype2 == 1) {
        this.otime2 = this.leftTime.toString().substring(0, 10);
      } else {
        this.otime2 = this.leftTime.toString();
      }
    },
    dotime() {
      if (this.dtime == "") {
        this.$alert("日期不能为空", "友情提示");
        return false;
      }
      if (this.dotype == 1) {
        this.otime = new Date(this.dtime).getTime() / 1000;
      } else {
        this.otime = new Date(this.dtime).getTime();
      }
    },
    dotime2() {
      if (this.otime2 == "") {
        this.$alert("时间戳不能为空", "友情提示");
        return false;
      }
      if (this.dotype2 == 1) {
        if (this.otime2.length == 10) {
          this.dtime2 = this.timestampToTime(this.otime2 * 1000);
        } else {
          this.$alert("(秒)时间戳为10位", "友情提示");
        }
      } else {
        if (this.otime2.length == 13) {
          this.dtime2 = this.timestampToTime(this.otime2 * 1);
        } else {
          console.log(this.otime2);
          this.$alert("（毫秒）时间戳为13位", "友情提示");
        }
      }
    },
    countTime() {
      var date = new Date();
      var leftTime = date.getTime();
      this.leftDate = this.timestampToTime(leftTime);
      this.leftTime = leftTime;
      setTimeout(this.countTime, 50);
    }
  },
  created() {
    this.countTime();
  }
};
</script>
