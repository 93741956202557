import sha1 from "sha1";
var AppKey = "111",
  AppSecret = 222;
export function getSign(params) {
  if (typeof params == "string") {
    return paramsStrSort(params);
  } else if (typeof params == "object") {
    var arr = [];

    for (var i in params) {
      if (i != "sign" && i != "text" && params[i] != null)
        arr.push(i + "=" + decodeURIComponent(params[i]));
    }
    // arr.push("timestamp" + "=" +Date.parse(new Date()))
    return paramsStrSort(arr.join("&"));
  }
}

export function paramsStrSort(paramsStr) {
  var url = paramsStr + "&appKey=" + AppKey;
  var urlStr = url
    .split("&")
    .sort()
    .join("&");
  var newUrl = urlStr + "&key=" + AppSecret;
  return sha1(newUrl);
}

// export default sign
