<template>
  <div>
    <span v-loading.fullscreen.lock="fullscreenLoading"> </span>
    <el-form
      :model="restPwdForm"
      :rules="rules"
      ref="restPwdForm"
      label-width="100px"
      class="register-form"
    >
      <el-form-item label="邮箱" prop="email">
        <el-input
          type="email"
          v-model="restPwdForm.email"
          autocomplete="off"
          id="email"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer-reg">
      <el-button
        type="primary"
        @click="restPwd('restPwdForm')"
        v-if="restPwdButton"
        >找回密码</el-button
      >
      <span v-if="!restPwdButton" class="red" style="margin-right:100px;"
        >邮件发送成功，请登录邮件系统进行找回！</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  props: {
    restPwdButton: Object
  },
  data() {
    var validateEmail = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      if (!value) {
        return callback(new Error("邮箱不能为空"));
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback();
        } else {
          callback(new Error("请输入正确的邮箱格式"));
        }
      }, 100);
    };

    return {
      fullscreenLoading: false,
      // restPwdButton: true,
      formVisble: {
        registerFormVisible: false,
        restPwdFormVisible: false
      },
      restPwdForm: {
        email: ""
      },
      rules: {
        email: [{ validator: validateEmail, trigger: "blur" }]
      }
    };
  },
  methods: {
    restPwd(restPwdForm) {
      var that = this;
      this.$refs[restPwdForm].validate(valid => {
        if (valid) {
          that.fullscreenLoading = true;
          that.restPwdForm.sign = that.$sign.getSign(that.restPwdForm);
          that.$http
            .post("/user/restpwdemail", that.restPwdForm)
            .then(function(res) {
              var data = JSON.parse(res.data);

              if (data.code == 200) {
                that.$alert(data.msg, "友情提示");
                that.restPwdButton = false;
              } else {
                that.$alert(data.msg, "友情提示");
              }
              that.fullscreenLoading = false;
            })
            .catch(function(err) {
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style scoped></style>
