<template>
  <div class="restPwdContent">
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="100px"
      class="restpwd-form"
    >
      <h2>你好：{{ form.email }}</h2>
      <h5>请输入新密码进行重置操作</h5>
      <el-form-item label="新 密 码" prop="pass">
        <el-input
          type="password"
          v-model="form.pass"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input
          type="password"
          v-model="form.checkPass"
          autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer-reg">
      <el-button type="primary" @click="restSubmit('form')">提交</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "RestPwd",
  components: {},
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      form: {
        pass: "",
        checkPass: "",
        email: " ",
        key: " "
      },
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }]
      }
    };
  },
  methods: {
    restSubmit(form) {
      var that = this;
      this.$refs[form].validate(valid => {
        if (valid) {
          that.form.sign = that.$sign.getSign(that.form);
          that.$http
            .put("/user/dorestPwd", that.form)
            .then(function(res) {
              var data = JSON.parse(res.data);

              if (data.code == 200) {
                that.$alert(data.msg, "友情提示", {
                  confirmButtonText: "密码重置成功，确认关闭并跳转首页！",
                  center: true,
                  callback: action => {
                    that.$router.push("/");
                    console.log(action);
                  }
                });
                // that.$alert(data.msg, "友情提示");
              } else {
                that.$alert(data.msg, "友情提示");
              }
            })
            .catch(function(err) {
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    index() {
      let token = this.$route.query.token;
      let email = this.$route.query.email;
      if (email) {
        this.form.email = email;
      }
      if (email) {
        this.form.key = token;
      }
    }
  },
  created() {
    this.index();
  }
};
</script>
