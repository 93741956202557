<template>
    <div class="link">
        <template>
            <span v-loading.fullscreen.lock="fullscreenLoading"> </span>
            <div class="block search-link">
                <el-button type="danger" class="fl" @click="handleDeletes">批量删除</el-button>
                <span class="search-link-span">关键词：</span>
                <el-input v-model="search" class="w200 search-link-input"></el-input>


                <span class="search-link-span">时间段：</span>
                <el-date-picker
                        class="search-link-input"
                        v-model="createTime"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="pickerOptions">
                </el-date-picker>

                <el-button type="primary" icon="el-icon-search" @click="searchSubmit" class="search-button">搜索
                </el-button>
            </div>

        </template>
        <el-table :data="linkData" style="width: 100%" @selection-change="handleSelect">
            <el-table-column
                    type="selection"
                    prop="id"
                    width="55">
            </el-table-column>
            <el-table-column label="原链接" prop="link"></el-table-column>
            <el-table-column label="短链接" prop="code">
                <template slot-scope="scope">{{local}}{{scope.row.code}}</template>
            </el-table-column>
            <el-table-column label="创建日期" prop="date"></el-table-column>
            <el-table-column label="过期时间" prop="expiration_time">
                <template slot-scope="scope"><span v-if="scope.row.expiration_code==5">永久</span><span
                        v-if="scope.row.expiration_code<5">{{scope.row.expiration_time}}</span></template>
            </el-table-column>
            <el-table-column align="center" label="操 作    " prop="">

                <template slot-scope="scope">
                    <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.$index, scope.row)"
                    >删除条目
                    </el-button
                    >
                </template>
            </el-table-column>


        </el-table>
        <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :page-size="data.per_page"
                :pager-count="11"
                :total="data.total">
        </el-pagination>
    </div>
</template>

<script>
  export default {
    name: "Link",
    data() {
      return {
        fullscreenLoading: false,
        data: [],
        page: 1,
        local: window.location.host + "/",
        linkData: [],
        selectedData: [],
        search: "",
        createTime: "",
        pickerOptions: {
          shortcuts: [{
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          }, {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          }, {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }]
        }
      };
    },

    methods: {
      getLinks() {
        var that = this;
        this.$http
          .get("/link", {
            params: {
              page: this.page,
              search: that.search,
              createTime: that.createTime
            }
          })
          .then(res => {
            if (res.data) {
              var data = JSON.parse(res.data);
              if (data.code == 200) {

                that.linkData = data.data.data;
                that.data = data.data;
              }
            }
          });
      },
      searchSubmit() {
        this.getLinks();
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        this.page = val;
        this.getLinks();
      },

      handleDelete(index, row) {
        this.$confirm("此操作将永久删除该链接, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          var that = this;
          var ids = [];

          ids.push(row["id"]);
          that.fullscreenLoading = true;
          that.$http
            .delete("/link", { params: { ids: ids.join(",") } })
            .then(function(res) {
              var data = JSON.parse(res.data);

              if (data.code == 200) {
                that.$alert(data.msg, "友情提示", {

                  callback: action => {
                    that.getLinks();
                    console.log(action);
                  }
                });
              } else {
                that.$alert(data.msg, "友情提示");
              }
              that.fullscreenLoading = false;
            })
            .catch(function(err) {
              console.log(err);
            });
        }).catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
      },
      handleSelect(data) {
        this.selectedData = data;
      },
      handleDeletes() {
        this.$confirm("此操作将永久删除该链接, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {


          var that = this;
          var ids = [];
          var arr = this.selectedData;
          if (arr) {
            that.fullscreenLoading = true;
            arr.forEach(function(item) {
              ids.push(item["id"]);
            });

            that.$http
              .delete("/link", { params: { ids: ids.join(",") } })
              .then(function(res) {
                var data = JSON.parse(res.data);

                if (data.code == 200) {
                  that.$alert(data.msg, "友情提示", {

                    callback: action => {
                      that.getLinks();
                      console.log(action);
                    }
                  });
                } else {
                  that.$alert(data.msg, "友情提示");
                }
                that.fullscreenLoading = false;
              })
              .catch(function(err) {
                console.log(err);
              });
          }
        }).catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
      }

    }, created() {
      this.getLinks();
    }
  };
</script>
