<template>
  <div class="home">
    <HomeContent msg="本网站提供免费生成短链接" />
  </div>
</template>

<script>
// @ is an alias to /src
import HomeContent from "@/components/HomeContent.vue";
import "@/assets/css/home.less";
export default {
  name: "Home",
  components: {
    HomeContent
  }
};
</script>
