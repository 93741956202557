<template>
  <div class="content">
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="Unicode编码" name="cunicode"
        ><CUnicode
      /></el-tab-pane>
      <el-tab-pane label="URL编码/解码" name="curl"><CUrl /></el-tab-pane>
      <el-tab-pane label="Base64编码/解码" name="cascii"
        ><CBase64
      /></el-tab-pane>
      <el-tab-pane label="Base64文件编码/解码" name="cbaseimage"
        ><CBase64Image
      /></el-tab-pane>
      <el-tab-pane label="Html编码/解码" name="chtml"><CHtml /></el-tab-pane>
      <el-tab-pane label="MD5加密" name="cmd5"><CMd5 /></el-tab-pane>
      <el-tab-pane label="SHA1加密" name="csha1"><CSha1 /></el-tab-pane>
      <el-tab-pane label="SHA256加密" name="csha256"><CSha256 /></el-tab-pane>
      <el-tab-pane label="SHA512加密" name="csha512"><CSha512 /></el-tab-pane>
      <el-tab-pane label="JSON美化" name="cjson"><CJson /></el-tab-pane>
      <el-tab-pane label="CSS/js代码压缩/解压缩" name="cstatic"
        ><CStatic
      /></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import CUnicode from "@/components/encryptions/CUnicode.vue";
import CUrl from "@/components/encryptions/CUrl.vue";
import CHtml from "@/components/encryptions/CHtml.vue";
import CBase64 from "@/components/encryptions/CBase64.vue";
import CBase64Image from "@/components/encryptions/CBase64Image.vue";
import CMd5 from "@/components/encryptions/CMd5.vue";
import CSha1 from "@/components/encryptions/CSha1.vue";
import CSha256 from "@/components/encryptions/CSha256.vue";
import CSha512 from "@/components/encryptions/CSha512.vue";
import CJson from "@/components/encryptions/CJson.vue";
import CStatic from "@/components/encryptions/CStatic.vue";

import "../assets/css/encryptions.less";
export default {
  name: "Encryptions",
  components: {
    CUnicode,
    CUrl,
    CHtml,
    CBase64,
    CBase64Image,
    CMd5,
    CSha1,
    CSha256,
    CSha512,
    CJson,
    CStatic
  },
  data() {
    return {
      activeName: "cunicode"
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab);
      console.log(event);
    }
  },
  created() {}
};
</script>
