import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Meta from "vue-meta";

Vue.use(Meta);
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui";

Vue.use(ElementUI);
import axios from "./config/http";

Vue.prototype.$http = axios;
Vue.config.productionTip = false;
import * as sign from "./config/sign";

Vue.prototype.$sign = sign;

router.beforeEach((to, from, next) => {
  if (to.meta.content) {
    let head = document.getElementsByTagName("head");
    let meta = document.createElement("meta");

    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", to.meta.content.keywords);
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", to.meta.content.description);
    meta.content = to.meta.content;
    head[0].appendChild(meta);
  }
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

new Vue({
  proxyTable: {
    "/api": {
      //将www.exaple.com印射为/apis
      target: "https://api.zsh.pub/api/", // 接口域名
      changeOrigin: true, //是否跨域
      pathRewrite: {
        "^/api": "/"
      }
    }
  },

  router,
  data: {
    title: "短链接，加密，解密，时间戳",
    keywords:
      "短链接,短链接生成,md5,sha1,sha256,sha512,json,加密,解密,php破解,时间戳",
    description:
      "免费生成短链接，短链接生成，md5，sha1，sha256，sha512，json，加密，解密，php破解，时间戳等"
  },
  //定义metaInfo
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: "keywords",
          content: this.keywords
        },
        {
          name: "description",
          content: this.description
        }
      ]
    };
  },

  store,
  render: h => h(App)
}).$mount("#app");
