<template>
  <div class="content">


    <h3>个人信息</h3>
    <div class="head">
      已绑定手机：{{ userForm.mobile
      }}<span class="fr" @click="editMobileVisible = true">修改绑定</span>
    </div>
    <div class="head">
      已绑定邮箱：{{ userForm.email
      }}<span
        class="fr"
        @click="
          editEmailVisible = true;
          restEmailButton = true;
          restEmailForm.email = '';
        "
        >修改绑定</span
      >
    </div>
    <el-dialog
      :visible.sync="smsVerifyDialog"
      width="350px"
      :append-to-body="true"
    >
      <slide-verify
        ref="slideblock"
        @again="onAgain"
        @fulfilled="onFulfilled"
        @success="onSuccess"
        @fail="onFail"
        @refresh="onRefresh"
        :accuracy="accuracy"
        :slider-text="text"
        :imgs="slideImages"
      ></slide-verify>

      <div>{{ msg }}</div>
    </el-dialog>
    <el-dialog
      title="手机修改"
      :visible.sync="editMobileVisible"
      class="register-dialog"
      width="500px"
    >
      <el-form
        :model="restMobileForm"
        :rules="rules"
        ref="restMobileForm"
        label-width="100px"
        class="register-form"
      >
        <el-form-item label="当前手机号">
          {{ userForm.mobile }}
        </el-form-item>
        <el-form-item label="新手机号" prop="mobile">
          <el-input
            type="mobile"
            v-model="restMobileForm.mobile"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item label="短信验证码" prop="smscode" class="sms-code ">
          <el-input
            v-model="restMobileForm.smscode"
            placeholder="请输入验证码"
          ></el-input>
          <el-button
            type="button"
            @click="sendCode"
            :disabled="smsDisabled"
            v-if="smsDisabled == false"
          >
            发送验证码
          </el-button>
          <el-button
            type="button"
            @click="sendCode"
            :disabled="smsDisabled"
            v-if="smsDisabled == true"
          >
            {{ btntxt }}
          </el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer-reg">
        <el-button type="primary" @click="restMobileSubmit('restMobileForm')"
          >确认修改</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="邮箱修改"
      :visible.sync="editEmailVisible"
      class="register-dialog"
      width="500px"
    >
      <el-form
        :model="restEmailForm"
        :rules="rules"
        ref="restEmail"
        label-width="100px"
        class="register-form"
      >
        <el-form-item label="新邮箱" prop="email">
          <el-input
            type="email"
            v-model="restEmailForm.email"
            autocomplete="off"
            id="email"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer-reg">
        <el-button
          type="primary"
          @click="restEmailSubmit('restEmail')"
          v-if="restEmailButton"
          >发送邮件</el-button
        >
        <span v-if="!restEmailButton" class="red" style="margin-right:30px;"
          >邮件发送成功，请登录邮件系统进行确认更换邮箱！</span
        >
      </div>
    </el-dialog>

    <div class="detail ">
      <el-form
        ref="form"
        :model="userForm"
        label-width="80px"
        label-position="left"
        class="user-form"
      >
        <el-divider></el-divider>
        <el-form-item label="昵称">
          <el-input v-model="userForm.nickname" class="input"></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-select v-model="userForm.sex" class="select">
            <el-option
              v-for="item in sexItem"
              :value="item.value"
              :key="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="生日">
          <el-date-picker
            v-model="userForm.birthday"
            type="date"
            placeholder="选择日期"
            class="input"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="详细地址">
          <el-input
            type="textarea"
            v-model="userForm.address"
            class="input"
          ></el-input>
        </el-form-item>
        <el-button class="w200 l20 fr" @click="submit" type="primary"
          >保存
        </el-button>
      </el-form>
      <el-divider></el-divider>
    </div>
  </div>
</template>

<script>
export default {
  name: "Info",
  data() {
    var validateEmail = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      if (!value) {
        return callback(new Error("邮箱不能为空"));
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback();
        } else {
          callback(new Error("请输入正确的邮箱格式"));
        }
      }, 100);
    };
    var validateMobile = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
      if (!value) {
        return callback(new Error("电话号码不能为空"));
      }
      setTimeout(() => {
        if (!Number.isInteger(+value)) {
          callback(new Error("请输入数字值"));
        } else {
          if (phoneReg.test(value)) {
            callback();
          } else {
            callback(new Error("电话号码格式不正确"));
          }
        }
      }, 100);
    };

    var validateSmscode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入短信验证码"));
      } else {
        if (this.restMobileForm.smscode !== "") {
          // this.$refs.form.validateField("smscode");
        }
        callback();
      }
    };
    return {
      editMobileVisible: false,
      editEmailVisible: false,
      restEmailButton: true,
      smsDisabled: false,
      smsVerifyDialog: false,
      time: 0,
      btntxt: "重新发送",
      text: "向右滑",
      msg: "",
      accuracy: 3,
      slideImages: [
        "http://alioss.zsihan.cn/zshpub-verify-img/1.jpg",
        "http://alioss.zsihan.cn/zshpub-verify-img/2.jpg",
        "http://alioss.zsihan.cn/zshpub-verify-img/3.jpg",
        "http://alioss.zsihan.cn/zshpub-verify-img/4.jpg",
        "http://alioss.zsihan.cn/zshpub-verify-img/5.jpg"
      ],
      sexItem: [
        {
          value: 0,
          label: "保密"
        },
        {
          value: 1,
          label: "男"
        },
        {
          value: 2,
          label: "女"
        }
      ],
      restEmailForm: {
        email: ""
      },
      restMobileForm: {
        mobile: "",
        smscode: ""
      },
      userForm: {
        nickname: "",
        mobile: "",
        sex: 1,
        email: "",
        birthday: "",
        address: ""
      },

      rules: {
        email: [{ validator: validateEmail, trigger: "blur" }],
        mobile: [{ validator: validateMobile, trigger: "blur" }],
        smscode: [{ validator: validateSmscode, trigger: "blur" }]
      }
    };
  },
  methods: {
    timer() {
      if (this.time > 0) {
        this.time--;
        this.btntxt = this.time + "s后重新获取";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = "获取验证码";
        this.smsDisabled = false;
      }
    },
    sendCode() {
      this.$refs.restMobileForm.validateField("mobile", errMsg => {
        if (!errMsg) {
          this.smsVerifyDialog = true;
        } else {
          return false;
        }
      });
    },
    restEmailSubmit(restEmailForm) {
      var that = this;
      this.$refs[restEmailForm].validate(valid => {
        if (valid) {
          that.fullscreenLoading = true;
          that.restEmailForm.sign = that.$sign.getSign(that.restEmailForm);
          that.$http
            .post("/user/restemail", that.restEmailForm)
            .then(function(res) {
              var data = JSON.parse(res.data);

              if (data.code == 200) {
                that.$alert(data.msg, "友情提示");
                that.restEmailButton = false;
              } else {
                that.$alert(data.msg, "友情提示");
              }
              that.fullscreenLoading = false;
            })
            .catch(function(err) {
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    restMobileSubmit(restMobileForm) {
      var that = this;
      this.$refs[restMobileForm].validate(valid => {
        if (valid) {
          that.fullscreenLoading = true;
          that.restMobileForm.sign = that.$sign.getSign(that.restMobileForm);
          that.$http
            .put("/user/restmobile", that.restMobileForm)
            .then(function(res) {
              var data = JSON.parse(res.data);

              if (data.code == 200) {
                that.$alert(data.msg, "友情提示", {
                  confirmButtonText: "确定",
                  callback: action => {
                    location.reload();
                    console.log(action);
                  }
                });
              } else {
                that.$alert(data.msg, "友情提示");
              }
              that.fullscreenLoading = false;
            })
            .catch(function(err) {
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    add0(m) {
      return m < 10 ? "0" + m : m;
    },
    submit() {
      if (this.userForm.address)
        if (this.userForm.address.length > 100) {
          this.$alert("地址不能超过100个字符", "友情提示");
        }
      var that = this;

      var date = this.userForm.birthday;

      if (date && typeof date != "string") {
        var Y = date.getFullYear() + "-";
        var M =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-";
        var D = this.add0(date.getDate()) + " ";

        this.userForm.birthday = Y + M + D;
      }

      this.userForm.sign = this.$sign.getSign(this.userForm);
      this.$http
        .put("/user/info", this.userForm)
        .then(function(res) {
          var data = JSON.parse(res.data);

          if (data.code == 200) {
            that.$alert(data.msg, "友情提示", {
              confirmButtonText: "确定",
              callback: action => {
                location.reload();
                console.log(action);
              }
            });
          } else {
            that.$alert(data.msg, "友情提示");
          }
        })
        .catch(function(err) {
          console.log(err);
        });
    },
    onSuccess() {
      var that = this;
      var sendMobileJson = {
        mobile: that.userForm.mobile,
        new_mobile: that.restMobileForm.mobile,
        sign: ""
      };
      sendMobileJson.sign = this.$sign.getSign(sendMobileJson);
      this.$http
        .post("/user/sendsms2", sendMobileJson)
        .then(function(res) {
          var data = JSON.parse(res.data);

          if (data.code == 200) {
            that.time = 60;
            that.smsDisabled = true;
            that.timer();
            that.smsVerifyDialog = false;
          } else {
            that.$alert(data.msg, "友情提示");
          }
        })
        .catch(function(err) {
          console.log(err);
        });
    },
    onFail() {
      // console.log("验证不通过");
      this.msg = "验证失败";
    },
    onRefresh() {
      // console.log("点击了刷新小图标");
      this.msg = "";
    },
    onFulfilled() {
      // console.log("刷新成功啦！");
    },
    onAgain() {
      // console.log("检测到非人为操作的哦！");
      this.msg = "try again";
      // 刷新
      this.$refs.slideblock.reset();
    },
    handleClick() {
      // 父组件直接可以调用刷新方法
      this.$refs.slideblock.reset();
    },
    index() {
      var that = this;
      this.$http
        .get("/user/info")
        .then(function(res) {
          if (res.data) var data = JSON.parse(res.data);
          if (data.code == 200) {
            that.userForm = data.data;
          } else {
            that.$alert(data.msg, "友情提示");
          }
        })
        .catch(function(err) {
          console.log(err);
        });
    }
  },

  created() {
    this.index();
  }
};
</script>
