<template>
  <div class="cunicode">
    <div class="block">
      <el-input
        class="ctextarea"
        type="textarea"
        placeholder="请输入内容"
        v-model="text"
        :rows="12"
      >
      </el-input>
      <div class="el-icon-right"></div>
      <el-input
        class="ctextarea"
        type="textarea"
        placeholder="请输入内容"
        v-model="textConvert"
        :rows="12"
      >
      </el-input>
    </div>
    <div class="block btns tr">
      <el-button type="primary" @click="jsonOptimize">JSON美化</el-button>
    </div>
  </div>
</template>

<script>
//optimize压缩
export default {
  name: "CJson",
  data() {
    return {
      textConvert: "",
      text: ""
    };
  },
  methods: {
    prettyFormat(str) {
      try {
        // 设置缩进为2个空格
        str = JSON.stringify(JSON.parse(str), null, 2);
        str = str
          .replace(/&/g, "&amp;")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;");
        return str.replace(
          /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\\-]?\d+)?)/g,
          function(match) {
            // return str.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function(match) {
            var cls = "number";
            if (/^"/.test(match)) {
              if (/:$/.test(match)) {
                cls = "key";
              } else {
                cls = "string";
              }
            } else if (/true|false/.test(match)) {
              cls = "boolean";
            } else if (/null/.test(match)) {
              cls = "null";
            }
            console.log(cls);
            return "" + match + "";
          }
        );
      } catch (e) {
        alert("异常信息:" + e);
      }
    },
    jsonOptimize() {
      this.textConvert = this.prettyFormat(this.text);
    }
  }
};
</script>
