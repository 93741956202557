<template>
  <div class="cbase64image">
    <div class="block">
      文件上传：

      <el-upload
        action=""
        :on-change="getFile"
        :limit="1"
        list-type="picture"
        :auto-upload="false"
      >
        <el-button size="small" type="primary"
          >选择文件上传并生成BASE64,一次只能上传一个文件</el-button
        >
      </el-upload>
      BASE64编码：
      <el-input
        class="ctextarea"
        type="textarea"
        placeholder="请输入内容"
        v-model="textConvert"
        :rows="12"
      >
      </el-input>
    </div>
    <div class="block btns tr">
      <el-button type="primary" @click="base64Encode">文件转Base64</el-button>
      <el-button type="primary" @click="base64Decode">Base64转文件</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CUrl",
  data() {
    return {
      textConvert: "",
      text: "",
      prefixBase64: "data:image/png;base64,",
      isFirefox: false
    };
  },
  mounted() {
    // 判断浏览器是否是火狐

    if (navigator.userAgent.indexOf("Firefox") > 0) {
      this.isFirefox = true;
    }
  },
  methods: {
    getBase64(file) {
      return new Promise(function(resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function() {
          imgResult = reader.result;
        };
        reader.onerror = function(error) {
          reject(error);
        };
        reader.onloadend = function() {
          resolve(imgResult);
        };
      });
    },
    handleDownloadQrIMg() {
      // 这里是获取到的图片base64编码,这里只是个例子哈，要自行编码图片替换这里才能测试看到效果

      const imgUrl = this.textConvert;

      // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片

      if (window.navigator.msSaveOrOpenBlob) {
        let bstr = atob(imgUrl.split(",")[1]);

        let n = bstr.length;

        let u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        let blob = new Blob([u8arr]);

        window.navigator.msSaveOrOpenBlob(blob, "chart-download");
      } else {
        // 这里就按照chrome等新版浏览器来处理

        let a = document.createElement("a");

        a.href = imgUrl;

        a.setAttribute("download", "chart-download");

        a.click();
      }
    },
    getFile(file) {
      if (file.size / 1024 / 1024 > 5) {
        this.$alert("文件不能超过5M", "友情提示");
        return false;
      }
      this.getBase64(file.raw).then(res => {
        this.text = res;
      });
    },
    base64Encode() {
      this.textConvert = this.text;
    },
    base64Decode() {
      this.handleDownloadQrIMg();
    }
  },
  loading: true
};
</script>
