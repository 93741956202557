<template>
  <div class="cunicode">
    <div class="block">
      <el-input
        class="ctextarea"
        type="textarea"
        placeholder="请输入内容"
        v-model="text"
        :rows="12"
      >
      </el-input>
      <div class="el-icon-right"></div>
      <el-input
        class="ctextarea"
        type="textarea"
        placeholder="请输入内容"
        v-model="textConvert"
        :rows="12"
      >
      </el-input>
    </div>
    <div class="block btns tr">
      <el-button type="primary" @click="sha1Encode">Sha1加密</el-button>
    </div>
  </div>
</template>

<script>
import sha1 from "sha1";
export default {
  name: "CUrl",
  data() {
    return {
      textConvert: "",
      text: ""
    };
  },
  methods: {
    sha1Encode() {
      this.textConvert = sha1(this.text);
    }
  }
};
</script>
