/**
 * ajax请求配置
 */
// import cookie from 'cookie'
import axios from "axios";
import apiURL from "./api.js";

//整理数据
axios.defaults.transformRequest = function(data) {
  //data = Qs.stringify(data);
  data = JSON.stringify(data);
  return data;
};

// 路由请求拦截
// http request 拦截器
axios.interceptors.request.use(
  config => {
    config.baseURL = apiURL;
    config.withCredentials = false; // 允许携带token ,这个是解决跨域产生的相关问题
    config.timeout = 3600 * 2;
    let token = localStorage.getItem("access_token");

    // let csrf = store.getters.csrf
    if (token) {
      config.headers.Authorization = "Token " + token;
      // config.headers = {
      //   "access-token": token,
      //   "Content-Type": "application/x-www-form-urlencoded"
      // };
    }
    if (config.url === "refresh") {
      config.headers = {
        "refresh-token": localStorage.getItem("refresh_token"),
        "Content-Type": "application/x-www-form-urlencoded"
      };
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 路由响应拦截
// http response 拦截器
axios.interceptors.response.use(
  response => {
    var data = response.data;

    if (data.code == 100001) {
      localStorage.removeItem("access_token");
      alert("登录超时！！！");
      location.reload();
    }
    if (response.data.code == "404") {
      console.log("response.data.resultCode是404");
      // 返回 错误代码-1 清除ticket信息并跳转到登录页面
      //      cookie.del("ticket")
      //      window.location.href='http://login.com'
      return;
    } else {
      return response;
    }
  },
  error => {
    return Promise.reject(error.response); // 返回接口返回的错误信息
  }
);
export default axios;
